import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import axios from "axios"
import Cookies from "universal-cookie"
import { isLoggedIn, getUser } from "../../../services/auth"
import config from "../../../config/config"

import Layout from "../../../templates/Page"
import Header from "../../ContentBuilder/Header"
import SwiperImageList from "../../ContentBuilder/SwiperImageList"
import CustomDesign from "../../ContentBuilder/SwiperList/CustomDesign"
import GroundFloorList from "./GroundFloorList"
import Level1RoomsList from "./Level1RoomsList"
import Subscribe from "../../Footer/subscribe"
import HomeDesignRelatedItems from "./HomeDesignRelatedItems"
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import { getRecentPages, setRecentPages } from "../../../services/account-services"
import RecentViews from "../../RecentViews"
import FeaturedProducts from "../../Block/FeaturedProducts"

const cookies = new Cookies()

var sent = false

const HomeDesignDetail = ({ data }) => {
  var user = getUser()
  const host = config.host
  const [homedesign, setHomeDesign] = useState({})
  const [globalConfig, setGlobalConfig] = useState({})
  const [slideVariation, setSlideVariation] = useState({})
  const [activeObjectID, setActiveObjectID] = useState(null)
  const changeSlideImage = slide => {
    setActiveObjectID(slide.objectID)
    setSlideVariation(slide)
  }
  var homedesign_name = homedesign.name
  var storeys = homedesign.Storeys
  var isDoulbeStorey = false
  if (typeof storeys !== "undefined" && storeys) {
    var storeyName = storeys.UndigitalStoreys.name
    if (storeyName === "Double Storey") {
      isDoulbeStorey = true
    }
  }
  var imagePosition = homedesign.imagePosition;
  var has_gallery = 0
  var gallery_images = []
  var rrpPrice = parseFloat(Math.round(homedesign.rrpPrice * 1000) / 1000).toFixed(2)
  var formattedPrice = "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  var lotSize = homedesign.lotWidth + " x " + homedesign.lotLength + "m"
  
  useEffect(() => {
    if (data) {
      setGlobalConfig(data.silverStripeDataObject.UndigitalGlobalConfig);
      let _homedesign = data.allSilverStripeDataObject.nodes[0].UndigitalHomeDesign;
      setHomeDesign(_homedesign)
      let FeaturedFloorPlan = _homedesign.FeaturedFloorPlan
      if (FeaturedFloorPlan !== "undefined" && FeaturedFloorPlan) {
        let featuredVariation = FeaturedFloorPlan.UndigitalFloorplan;

        if (typeof window !== "undefined") {
          const params = new URLSearchParams(window.location.search)
          const vid = params.get('vid')
          if (!vid) {
            setSlideVariation(featuredVariation)
            if (activeObjectID === null) {
              setActiveObjectID(featuredVariation.objectID)
            }
          }
        }
      }
    }
  }, [JSON.stringify(data)])

  if (isDoulbeStorey) {
    if (slideVariation.groundFloorImagePath) {
      var groundFloor_img = {
        UndigitalGalleryImage: {
          imagePath: slideVariation.groundFloorImagePath,
          imagePosition: imagePosition,
        },
      }
      gallery_images.push(groundFloor_img)
    }
    if (slideVariation.firstFloorImagePath) {
      var firstFloor_img = {
        UndigitalGalleryImage: {
          imagePath: slideVariation.firstFloorImagePath,
          imagePosition: imagePosition,
        },
      }
      gallery_images.push(firstFloor_img)
    }
  } else {
    if (slideVariation.groundFloorImagePath) {
      var groundFloor_img = {
        UndigitalGalleryImage: {
          imagePath: slideVariation.groundFloorImagePath,
          imagePosition: imagePosition,
        },
      }
      gallery_images.push(groundFloor_img)
    }
  }

  if (slideVariation) {
    homedesign_name += " " + slideVariation.name
  }
  const homedesign_id = homedesign.objectID
  const gallery = homedesign.Gallery
  const imagePath = homedesign.ImagePath;
  const designers = homedesign.Designers
  var designersList = []
  var designersName = ""
  if (typeof designers !== "undefined" && designers) {
    var i = 0
    for (; designers[i];) {
      designersList[i] = designers[i].UndigitalDesigner.name
      i++
    }
  }
  designersName = designersList.join(", ")
  const [addedToBoard, setAddedToBoard] = useState(false)
  var boards = []
  if (isLoggedIn()) {
    boards = Object.keys(user.Boards).map(key => user.Boards[key])
    var y = 0
    for (; boards[y];) {
      let _board = boards[y]
      let _board_items = _board.Items
      let _board_items_objs = JSON.parse(_board_items)
      let _board_items_arr = _board_items_objs ? Object.keys(_board_items_objs).map(
        key => _board_items_objs[key]
      ) : []
      let _item_exist = _board_items_arr.find(x => x.ID === homedesign.objectID)
      if (!addedToBoard && _item_exist) {
        setAddedToBoard(true)
      }
      y++
    }
  }

  const [allVariations, setAllVariants] = useState([])

  useEffect(() => {
    var _allVariations = []
    const variations = homedesign.Floorplans
    const valid_status = ["Active"]
    if (typeof variations !== undefined && variations) {
      let i = 0
      for (; variations[i];) {
        let variation = variations[i].UndigitalFloorplan
        variation.image = variation.groundFloorImagePath
        let isValid = valid_status.includes(variation.status)
        if (isValid) {
          _allVariations[i] = variation
        }
        i++
      }
    }
    setAllVariants(_allVariations)
  }, [homedesign])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search)
      const vid = params.get('vid')
      if (vid) {
        const currentVariation = allVariations.find(v => v.objectID == vid)
        if (currentVariation) {
          changeSlideImage(currentVariation)
        }
      }
    }
  }, [allVariations])

  const swiper_params = {
    spaceBetween: 10,
    navigation: {
      prevEl: ".swiper-button-prev-unique",
      nextEl: ".swiper-button-next-unique",
    },
    breakpoints: {
      1200: {
        slidesPerView: 5,
      },
      1100: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
  }

  let isSaved = false
  for (const board of boards) {
    const items = JSON.parse(board.Items)
    for (const key in items) {
      if (key.includes('HomeDesign') && items[key].ID == homedesign.objectID) {
        isSaved = true
      }
    }
  }

  var logged = isLoggedIn() ? 1 : 0
  var user_email = cookies.get("user_email")
  if (typeof user_email === "undefined") {
    user_email = ""
  }
  if (user.Email) {
    user_email = user.Email
  }

  if (homedesign_name && !sent) {
    var user_key = cookies.get("user_key")
    if (typeof user_key === "undefined") {
      var date = new Date()
      var time = date.getTime()
      var days_to_save = 365 * 24 * 60 * 60 * 1000 // 365 days
      cookies.set("user_key", time, {
        path: "/",
        expires: new Date(Date.now() + days_to_save),
      })
    }
    axios
      .get(
        host +
        "/tribecaapi/homedesignview?key=" +
        user_key +
        "&email=" +
        user_email +
        "&id=" +
        homedesign_id +
        "&logged=" +
        logged
      )
      .then(response => { })
      .catch(function (error) { })

    sent = true
  }

  const showAddBoard = () => {
    if (isLoggedIn()) {
      setShowCreateBoard(true)
    } else {
      if (typeof window !== "undefined") {
        window.location.href = "/login/"
      }
    }
  }

  const [showCreateBoard, setShowCreateBoard] = useState(false)

  if (homedesign && homedesign.status === 'Active') {
    const recentPages = getRecentPages()
    setRecentPages([
      { ...homedesign, className: 'HomeDesign' },
      ...recentPages.filter(rp => !(rp.className == 'HomeDesign' && rp.objectID == homedesign.objectID))
    ])
  }

  return (
    <Layout>
      <CreateBoardModal
        show={showCreateBoard}
        handleClose={() => setShowCreateBoard(false)}
        objectClass="HomeDesign"
        objectID={homedesign.objectID}
      />
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/explore/home-designs/">Home Designs</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {homedesign.name}
            </li>
          </ol>
        </nav>
        <div>
          <div className="mt-30 mb-30 border-bottom-7px">
            <div className="row align-items-center">
              <div className="col-md">
                <h1 className="product-name-title">{homedesign.name}</h1>
              </div>
              <div className="col-md-auto">
                <button type="button" className="button-link">
                  {addedToBoard ? (
                    <div
                      className="d-flex align-items-center mb-2 mb-md-0"
                      onClick={() => showAddBoard()}
                    >
                      Added to board
                      <svg
                        focusable="false"
                        width="30px"
                        height="30px"
                        className="text-black ml-3"
                      >
                        <use xlinkHref="/img/icons.svg#icon-heart"></use>
                      </svg>
                    </div>
                  ) : (
                    <div
                      className="d-flex align-items-center mb-2 mb-md-0"
                      onClick={() => showAddBoard()}
                    >
                      {isSaved ? 'Added to board' : 'Add to board'}
                      {isSaved ? (
                        <svg className="text-black ml-3" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                          <path id="Path_878" data-name="Path 878" d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
                        </svg>
                      ) : <svg
                        focusable="false"
                        width="30px"
                        height="30px"
                        className="text-black ml-3"
                      >
                        <use xlinkHref="/img/icons.svg#icon-heart"></use>
                      </svg>}
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="wrap-section row">
            <div className="col-md-8">
              <div className="d-flex flex-column flex-sm-row justify-content-between wrap-section border-bottom-4px feature-title">
                <h3 className="mb-0">{slideVariation.name}</h3>
                <div className="d-flex mx-n2 mb-2 mb-sm-0">
                  <div className="mx-2 item-feature">
                    {slideVariation.bed}
                    <svg width="26" height="26">
                      <use xlinkHref="/img/icons.svg#icon-bed-stroke"></use>
                    </svg>
                  </div>
                  <div className="mx-2 item-feature">
                    {slideVariation.bath}
                    <svg width="26" height="26">
                      <use xlinkHref="/img/icons.svg#icon-bath-stroke"></use>
                    </svg>
                  </div>
                  <div className="mx-2 item-feature">
                    {slideVariation.living}
                    <svg width="26" height="26">
                      <use xlinkHref="/img/icons.svg#icon-sofa-stroke"></use>
                    </svg>
                  </div>
                  <div className="mx-2 item-feature">
                    {slideVariation.garage}
                    <svg width="26" height="26">
                      <use xlinkHref="/img/icons.svg#icon-garages-stroke"></use>
                    </svg>
                  </div>
                </div>
              </div>
              {gallery_images && <CustomDesign gallery={gallery_images} />}
            </div>
            <div className="col-md-4">
              <h3 className="mb-2 border-bottom-4px info-title">
                Information
              </h3>
              <div className="list-group list-group-flush mb-4">
                {globalConfig.hidePricingGlobally == 0 && (
                  <div className="list-group-item px-0">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Price from</h5>
                      </div>
                      <div className="col-auto">
                        <p className="h4">
                          {slideVariation.formattedPrice}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="list-group-item px-0">
                  <div className="row">
                    <div className="col">
                      <h5 className="mb-0">Designer</h5>
                    </div>
                    <div className="col-auto">
                      <p className="font-weight-medium">{designersName}</p>
                    </div>
                  </div>
                </div>
                <div className="list-group-item px-0">
                  <div className="row">
                    <div className="col">
                      <h5 className="mb-0">Approx House area</h5>
                    </div>
                    <div className="col-auto">
                      <p className="font-weight-medium">
                        {homedesign.homeArea} sqm
                      </p>
                    </div>
                  </div>
                </div>
                <div className="list-group-item px-0">
                  <div className="row">
                    <div className="col">
                      <h5 className="mb-0">Designed for lots</h5>
                    </div>
                    <div className="col-auto">
                      <p className="font-weight-medium">
                        {lotSize}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="list-group-item px-0">
                  <h5 className="mb-0">About</h5>
                  <span
                    dangerouslySetInnerHTML={{ __html: homedesign.description }}
                  />
                </div>
              </div>

              {slideVariation.GroundFloorRoomsAmenities ? (
                <GroundFloorList list={slideVariation.GroundFloorRoomsAmenities} />
              ) : (
                ""
              )}
              {slideVariation.Level1RoomsAmenities ? <Level1RoomsList list={slideVariation.Level1RoomsAmenities} /> : ""}

              <div className="row gutters-10 mt-30">
                <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                  <button
                    type="button"
                    className="btn btn-outline-black btn-block btn-lg px-2 my-1"
                  >
                    <div
                      className="d-flex align-items-middle justify-content-center"
                      onClick={() => showAddBoard()}
                    >
                      <svg
                        focusable="false"
                        width="24px"
                        height="24px"
                        className="mr-2"
                      >
                        <use xlinkHref="/img/icons.svg#icon-heart"></use>
                      </svg>
                      {isSaved ? 'Added to board' : 'Add to board'}
                    </div>
                  </button>
                </div>
                <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                  <a href="/contact-us/" className="btn btn-black btn-block btn-lg px-2 my-1">Enquire now</a>
                </div>
              </div>
            </div>
          </div>
          {allVariations.length > 1 && (
            <div className="wrap-section row">
              <div className="col-12 col-md-8">
                <div className="other-available-wrap mt-5">
                  <h3 className="main-title">Other available options: </h3>
                  <div className="other-available">
                    <Swiper navigation {...swiper_params}>
                      {allVariations.map((variation, idx) => (
                        <SwiperSlide key={`variation${idx}`}>
                          <div
                            onClick={() => changeSlideImage(variation)}
                            className="product-items clickable"
                          >
                            <div className={`product-items-thumb ${(variation.objectID === activeObjectID) ? 'active' : ''} ${imagePosition}`}>
                              <img
                                alt=""
                                src={variation.image}
                                alt=""
                              />
                            </div>
                            <p className="product-items-desc">
                              {variation.name}
                              {globalConfig.hidePricingGlobally == 0 && (
                                <>
                                  - {formattedPrice}
                                </>
                              )}
                            </p>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="swiper-button-prev-unique"></div>
                    <div className="swiper-button-next-unique"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <HomeDesignRelatedItems id={homedesign.objectID} />
          <RecentViews />
        </div>
      </div>
      <Subscribe></Subscribe>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
          hidePricingGlobally
        }
      }
    allSilverStripeDataObject(
      filter: {
        UndigitalHomeDesign: {
          urlSegment: { eq: $slug }
          status: { in: ["Active", "Draft"] }
        }
      }
    ) {
      nodes {
        UndigitalHomeDesign {
            status
            name
            urlSegment
            description
            brochurePath
            objectID
            homeArea
            homeSize
            rrpPrice
            lotWidth
            lotLength
            imagePath
            imagePosition
            FeaturedFloorPlan {
              UndigitalFloorplan {
                status
                objectID
                name
                bath
                bed
                garage
                living                
                firstFloorImagePath
                groundFloorImagePath
                GroundFloorRoomsAmenities {
                  UndigitalRoomAmenity {
                    name
                    objectID
                  }
                }
                Level1RoomsAmenities {
                  UndigitalRoomAmenity {
                    name
                    objectID
                  }
                }            
              }
            }
            HomeType {
              UndigitalHomeType {
                name
              }
            }
            Gallery {
              UndigitalGalleryImage {
                imagePath
              }
            }
            Floorplans {
              UndigitalFloorplan {
                status
                objectID
                featuredFloorplan
                name
                bath
                bed
                garage
                living
                firstFloorImagePath
                groundFloorImagePath
                GroundFloorRoomsAmenities {
                  UndigitalRoomAmenity {
                    name
                    objectID
                  }
                }
                Level1RoomsAmenities {
                  UndigitalRoomAmenity {
                    name
                    objectID
                  }
                }            
              }
            }
            Designers {
              UndigitalDesigner {
                name
              }
            }
            Storeys {
              UndigitalStoreys {
                name
              }
            }        
          }
      }
    }
  }
`

export default HomeDesignDetail
